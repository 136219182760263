<template>
    <div>
        <b-row>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('code')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'code') }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('faculty')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'faculty_code') }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
                <b-form-group :label="$t('name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input" v-html="getObjectValue(formData, 'name')"></div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('short_name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input" v-html="getObjectValue(formData, 'short_name')"></div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('diploma_name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input" v-html="getObjectValue(formData, 'diploma_name')"></div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('head_of_department')">
                    <div class="label-as-input">
                        {{ getObjectValue(formData, 'head_of_department') }} - {{ getObjectValue(formData, 'head_of_department_name') }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('credit_limit')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'credit_limit') }}</div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('status')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'status') }}</div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import DepartmentsService from '@/services/DepartmentsService';

    export default {
        components: {
            DepartmentsService
        },
        props: {
            formId: {
                type: String
            }
        },
        data() {
            return {
                formData: {},
                formLoading: false
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                DepartmentsService.get(id)
                                  .then(response => {
                                      this.formData = response.data.data;
                                  })
                                  .catch(e => {
                                      this.showErrors(e)
                                  })
            }
        }
    }
</script>
